import React, { useState } from "react";
import "./App.css";
import { FileUploader } from "react-drag-drop-files";
import { sha256 } from "js-sha256";
import axios from "axios";
import logo from './assets/images/upload_icon.png';

const environments = {
  dev: "development",
  staging: "staging",
  prod: "production"
}

const Uploader = () => {
  return (
    <div className="dragger-container row">
      <img className="logo" src={logo} alt="" />
      <p className="title">Drop a file here or click to upload</p>
    </div>
  )
}

function App() {
  const [trx, setTrx] = useState(null);
  const [hash, setHash] = useState('');
  const [invalid, setInvalid] = useState(false);

  const handleChange = (file: any) => {
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = async () => {
      const binaryStr = reader.result;
      const hashResult = sha256(binaryStr as any);

      if (hashResult) {
        // Get trx from hash
        setHash(hashResult);
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/item/${hashResult}`)
          .then((res) => {
            if (res.data.trx) {
              setInvalid(false);
              setTrx(res.data.trx);
            } else {
              setInvalidFile();
            }
          })
          .catch((err) => {
            setInvalidFile();
          });
      }else{
        setHash('');
        setInvalidFile();
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const setInvalidFile = () => {
    setTrx(null);
    setInvalid(true);
  }

  const getBlockchainUrl = (trx: string) => {
    if( process.env.REACT_APP_ENV === environments.prod ){
      return `https://bloks.io/transaction/${trx}`;
    }

    return `https://jungle4.eosq.eosnation.io/tx/${trx}`;
  }

  return (
    <div className='App'>
      <div className='App-header'>
        <div className="bg-container" style={ trx || invalid ? { height: '50%', width: '80vh' } : {}}></div>
        <p className="title">Upload your file to verify its authenticity on the blockchain</p>
        <FileUploader
          handleChange={handleChange}
          name='file'
          children={<Uploader />}
        />

        {hash && (
          <div className='App-hash'>
            <p style={{ fontSize: 'small' }}>
              Resulting hash of the file  
            </p>
            <p style={{ fontSize: 'small', marginTop: 0 }}>
              {hash}
            </p>
          </div>
        )}

        {trx && (
          <>
            <p className='App-check'>File is valid!</p>

            <a
              className='App-link'
              rel="noreferrer"
              target='_blank'
              href={getBlockchainUrl(trx)}
            >
              Transaction: {trx}
            </a>
          </>
        )}

        {invalid && (
          <p className='App-error'>
            A trx corresponding to that file wasn't found.
          </p>
        )}
      </div>
    </div>
  );
}

export default App;
